<template>
  <confirm-or-receive-cash-transaction-form transaction-type="receive-cash" />
</template>

<script>
import ConfirmOrReceiveCashTransactionForm from "@/components/transactions/ConfirmOrReceiveCashTransactionForm.vue";

export default {
  components: {
    ConfirmOrReceiveCashTransactionForm,
  },
};
</script>
